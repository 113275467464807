/*
* システム管理者 管理画面用
*/

$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  }
});

/*
* バリデーションエラー表示
* errors - JSON形式のエラーメッセージ
*/
showValidationErrors = function(errors) {
  // エラー表示追加
  $('.errorMessages ul li').remove();
  for (let i in errors) {
    $('.errorMessages ul').append('<li>' + errors[i] + '</li>');
  }
  // 表示
  $('.errorMessages').removeClass('d-none');
};

$(function () {

  /*
  * テーブル Drag & Drop
  */
  $(".tableSort").tableDnD({
    dragHandle: '.handle',
    onDrop: function(table, row) {
      // Dragされたとき
      var saveData = [];
      // 更新するテーブル名取得（data-sourceはtbodyに設定すること）
      saveData = {'table_name' : $(table).attr('data-source') };
      // tableの行取得
      var rows = table.rows;
      var dataIds = [];
      // テーブル名取得
      for(var i = 0; i < rows.length; i++) {
        dataIds[i] = $(rows[i]).attr('id');
      }
      // 登録用JSON作成
      saveData['ids'] = dataIds;
      // 登録処理
      $.ajax({
        headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        url: '/api/change_order',
        type: 'POST',
        data: saveData,
      })
      .done(function(data){
        // 成功
        alert(data);
      })
      .fail(function(XMLHttpRequest, textStatus, errorThrown){
        alert(errorThrown);
      });
    },
  });

});
